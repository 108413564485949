<template>
  <div class="relative p-5 overflow-y-auto h-96">
    <h1 class="text-center font-bold">Follow Note</h1>
    <div class="flex justify-end p-5 absolute top-0 right-0">
      <button
        class="rounded-lg px-4 py-2 bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB]"
        @click="toggleOffcanvas"
      >
        Add Note
      </button>
    </div>

    <!-- Display Notes -->
    <div class="flex flex-col gap-y-2">
      <div
        class="flex flex-col gap-y-1 bg-gray-100 w-[80%] my-1 shadow-lg rounded-md p-4"
        v-for="note in notes"
        :key="note.id"
      >
        <h1 class="font-semibold">{{ note.title }}</h1>
        <p class="text-md">{{ note.note }}</p>
        <p class="text-xs">
          Created by
          {{ note.user.email }} @
          {{ new Date(note.created_date).toLocaleDateString() }} -
          {{ new Date(note.created_date).toLocaleTimeString() }}
        </p>
      </div>
    </div>

    <div
      class="bg-[#2563EB] text-white font-bold absolute top-0 right-0 border h-[380px] transform transition-transform duration-300 p-5 rounded-lg"
      :class="{
        '-translate-x-full hidden': !isOffcanvasOpen,
        'translate-x-0': isOffcanvasOpen,
      }"
    >
      <h1 class="text-center">Enter Note</h1>
      <form @submit.prevent="saveNote" action="" class="">
        <div class="flex flex-col mb-4">
          <label for="title" class="mb-1">Title</label>
          <input
            type="text"
            class="border outline-none rounded-lg text-black font-medium px-3 py-1.5"
            name="title"
            v-model="title"
            id="title"
          />
        </div>
        <div class="flex flex-col">
          <label for="note" class="mb-1">Note</label>
          <textarea
            :style="{ resize: 'none' }"
            name="note"
            id="note"
            class="border outline-none rounded-lg text-black font-medium px-3 overflow-y-scroll"
            cols="30"
            rows="6"
            v-model="noteContent"
          ></textarea>
        </div>
        <div class="flex justify-between pt-5">
          <button
            type="submit"
            class="px-4 py-2 rounded-lg bg-white text-[#2563EB] hover:bg-gradient-to-r from-blue-300 via-blue-600 to-[#2563EB] hover:text-white"
          >
            Save Note
          </button>
          <button
            class="px-4 py-2 rounded-lg bg-white text-[#2563EB] hover:bg-gradient-to-r from-blue-300 via-blue-600 to-[#2563EB] hover:text-white"
            @click="closeOffcanvas"
          >
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "FollowNote",
  props: {
    leadId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOffcanvasOpen: false,
      notes: [],
      noteContent: "",
      title: "",
    };
  },
  computed: {
    ...mapGetters(["getToken", "isAuthenticated"]),
  },
  methods: {
    toggleOffcanvas() {
      this.isOffcanvasOpen = !this.isOffcanvasOpen;
    },
    closeOffcanvas() {
      this.isOffcanvasOpen = false;
      this.title = "";
      this.noteContent = "";
    },
    async saveNote() {
      const noteData = {
        title: this.title,
        lead: this.$route.params.id,
        user: this.$store.getters.getUserDetails.id,
        note: this.noteContent,
      };
      console.log(noteData);

      axios
        .post(
          `${process.env.VUE_APP_API}/api/lead-follow-up/notes/post/`,
          noteData,

          {
            headers: {
              Authorization: `Token ${this.getToken}`,
            },
          }
        )
        .then((response) => {
          console.log("Note saved successfully:", response.data);
          this.fetchNotes();
          this.closeOffcanvas();
        })
        .catch((error) => {
          console.error("Error saving note:", error);
        });
    },

    async fetchNotes() {
      console.log(this.$route.params.id);
      axios
        .get(
          `${process.env.VUE_APP_API}/api/lead-follow-up/list/?lead__id=${this.$route.params.id}`,
          {
            headers: {
              Authorization: `Token ${this.getToken}`,
            },
          }
        )
        .then((response) => {
          console.log("Notes fetched successfully:", response.data);
          if (response.data.status == 200) {
            this.notes = response.data.data;
          } else {
            this.notes = [];
          }
        })
        .catch((error) => {
          console.error("Error fetching notes:", error);
        });
    },
    formatCreatedDate(dateString) {
      const date = new Date(dateString);
      const options = { year: "numeric", month: "long", day: "numeric" };
      return date.toLocaleDateString("en-US", options);
    },
  },
  async created() {
    await this.fetchNotes();
  },
};
</script>
